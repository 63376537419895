body {
  margin: 0;
  background-color: #111 !important;
  background: url('/src/image/background_image.jpg') no-repeat center center; 
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



header {
  background-color: rgba(54, 28, 6, 0.60) !important; 
}


:root {
  --background-color: #fff;
  --foreground-color: #333;
  --link-color-hue: 151;
  --link-color: hsl(var(--link-color-hue),44.7%,50%);
  --link-color-hover: hsl(var(--link-color-hue),44.7%,45%);
  --signature-color: #666;
  --date-color: #aaa;
  --header-background-color: #111;
  --header-foreground-color: #eee;
  --header-link-color: #ddd;
  --code-background-color: #f6f6f6;
  --code-foreground-color: #444;
}

html {
  height: 100%;
}
body {
  min-height: 100%;
  font-family: 'Merriweather Sans', sans-serif;
  background-color: var(--background-color);
  color: var(--foreground-color);
}

a, a:focus {
  transition: color .3s ease-in-out;
  color: var(--link-color);
}
a:hover, a:active {
  color: var(--link-color-hover);
}

ul, ol {
  padding-left: 25px;
}

code {
  color: var(--code-foreground-color);
  background-color: var(--code-background-color);
}
pre code {
  overflow-x: auto;
  overflow-wrap: normal;
  white-space: pre;
}
blockquote {
  font-size: 14px;
}

header {
  background-color: var(--header-background-color);
  color: var(--header-foreground-color);
  padding: 40px;
}
header h1 {
  text-transform: uppercase;
  font-size: 50px;
  letter-spacing: 15px;
  margin: 0 0 0 4px;
}
@media (max-width: 767px) {
  header {
    padding: 30px;
  }
  header h1 {
    letter-spacing: 5px;
    font-size: 40px;
  }
  .list-inline li {
    display: list-item;
  }
}

header h1 a, header h1 a:focus {
  color: #eee;
}
header h1 a:hover {
  text-decoration: none;
}
header ul {
  margin: 25px 0 0;
}
header ul li {
  padding-bottom: 20px;
}
header ul a {
  color: var(--header-link-color-primary);
  font-weight: 700;
}
header ul a:hover, header ul a:active {
  color: var(--link-color);
  text-decoration: none;
}
header ul a span {
  opacity: 0.7;
  font-weight: 300;
  padding-right: 2px;
}

.content {
  padding: 40px;
  max-width: 1000px;
  line-height: 22px;
}
@media (max-width: 767px) {
  .content {
    padding: 30px;
  }
}
.content h2 {
  margin-top: 0;
}
.content h3 {
  margin-top: 25px;
  margin-bottom: 15px;
}
.content h4 {
  margin-top: 25px;
  margin-bottom: 15px;
}
.content p {
  margin-bottom: 15px;
}
.content p:last-child {
  margin-bottom: 0;
}
.content ul {
  margin-bottom: 15px;
}
.content li {
  margin-bottom: 15px;
}
.content img {
  max-width: 100%;
}
.content .footnotes {
  margin-top: 60px;
  font-size: 12px;
}
.content .footnotes:before {
  content: "Footnotes:";
  margin-bottom: 10px;
  display: block;
  font-style: italic;
}
/* Not the foot note items themselves, but any list items contained inside. */
.content .footnotes ol li li {
  margin-bottom: 10px;
}

p.date {
  color: var(--date-color);
}
p.date a, p.date a:visited {
  color: var(--date-color) !important;
  text-decoration: underline;
}
p.date a:hover, p.date a:active {
  color: var(--link-color) !important;
}
p.signature {
  margin-top: 60px;
  color: var(--signature-color);
}

.post h2 {
  margin-bottom: 20px;
}

.date-header {
  font-weight: bold;
  font-family: monospace;
}

.presentation-list .date {
  font-family: monospace;
  padding-right: 10px;
}
.presentation-list small {
  padding-left: 5px;
}
@media (max-width: 767px) {
  .presentation-list .date {
    display: block;
  }
  .presentation-list small {
    display: block;
    padding-left: 0;
  }
}

.presentation section {
  margin-top: 20px;
  margin-botom: 20px;
}
.presentation .description {
  margin-bottom: 50px;
}

.presentation .youtube,
.presentation .vimeo {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.presentation .youtube iframe,
.presentation .vimeo iframe,
.presentation .youtube object,
.presentation .vimeo object,
.presentation .youtube embed,
.presentation .vimeo embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

img.float-right {
  margin: 0 1em .5em 1em;
  float: right;
}
@media (max-width: 500px) {
  img.float-right {
    float: none;
    display: block;
    margin: 1em auto;
  }
}

.anchorjs-link, .anchorjs-link:hover {
  text-decoration: none;
}


