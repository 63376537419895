.App {
  text-align: center;
}

.Publication {
  text-align: top;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.Publications-header {
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(20px + 2vmin);
  color: white;
  height: fit-content; 
}

.full-screen-container {
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;      /* Horizontally center content */
  align-items: flex-start;          /* Vertically center content */
  height: 100vh;                /* Full viewport height */
  width: 100vw;                 /* Full viewport width */
  padding: 60px;                /* Padding for small screens */
  box-sizing: border-box;       /* Ensures padding is included in the element's total width and height */
  background-color: rgba(54, 28, 6, 0.60) !important;   /* Background color (change as needed) */
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blogs{

  height: 700px;
  overflow: scroll;
  text-align: left;
  color: white;
}

.link {
  color: #FFF;
}
.link:hover {
   color: rgb(70, 121, 70)
}
.hr{
  border-top: 1px solid white;
}
.name{
  padding-bottom: 35px;
}

.date{
  color: #FFF;
}
.blog_card{
  background-color: rgba(85, 92, 55, 0.6);
  height: 200px;
  overflow: scroll;
}